import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import {
  MatLegacyColumnDef as MatColumnDef,
  MatLegacyHeaderRowDef as MatHeaderRowDef,
  MatLegacyRowDef as MatRowDef,
  MatLegacyTable as MatTable,
  MatLegacyHeaderCell as MatHeaderCell,
  MatLegacyHeaderCellDef,
  MatLegacyColumnDef,
} from '@angular/material/legacy-table';
import { MatSort, MatSortable } from '@angular/material/sort';
import { GqlTableDataSource } from '@common/classes/gql.table.data-source';
import { TablePaginator } from '@common/classes/table-paginator';
import { ReturnUrlService } from '@common/services/return-url.service';
import { Query } from 'apollo-angular';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import * as XLSX from 'xlsx';

import { CLICKABLE_LINKS } from '../link/link.component';
import { TableContextDirective } from './table-context.directive';
import { ColumnDef, GqlTableService } from './gql.table.service';
import { PageRightDrawerContentDirective } from '@common/directives/page-right-drawer-content.directive';
import { MatDrawer } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';

export const CLICKABLE_ROWS = new InjectionToken<boolean>('CLICKABLE_ROWS');
export const COLUMNS_VISIBILITY_CONTROL = new InjectionToken<boolean>(
  'COLUMNS_VISIBILITY_CONTROL',
);
/**
 * TableComponent
 * ========================================
 * This component is a wrapper for "mat-table".
 * The data source for the table is provided through the dataService input field, which accepts a table service inherited from GqlTableService.
 * The table service is actually the GqlTableService configurator.
 * Table's column templates (mat-cell, mat-row etc) are passed via the TableComponent and added dynamically to MatTable definitions.
 */

/**
 * GqlTableService
 * ========================================
 * All table services are inherited from GqlTableService.
 * The GqlTableService is meant to serve as a place to encapsulate any sorting, filtering, pagination, and data retrieval logic
 * as well as misc features (columns reordering, hiding etc).
 */

/**
 * Features
 * ========================================
 * Columns reordering
 * ----------------------------------------
 * This feature is provided by TableDropListDirective and TableDragElementDirective.
 *
 * This component has two input fields related to reordering columns.
 * The first field allowColumnReordering disables column reordering.
 * The second field persistColumns disables the persistence of columns settings.
 * ----------------------------------------
 *
 *
 * Hide and Show columns
 * ----------------------------------------
 * This component has one input field related to hiding and showing columns.
 * The field columnsVisibilityControl disables the hide/show of columns settings.
 *
 * NOTE: Localization in the dropdown for the show/hide columns feature is not implemented.
 * All column names will be displayed in English only.
 */

@Component({
  selector: 'assets-table',
  templateUrl: 'table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<
    T,
    QV,
    GQL extends Query,
    F extends UntypedFormGroup,
  >
  implements OnInit, AfterViewInit, AfterContentInit, OnDestroy
{
  protected readonly destroy$ = new Subject<void>();
  protected readonly currentNavigationState = inject(Location).getState();
  selection = new SelectionModel<T>(true);
  tablePaginator = new TablePaginator();
  protected contextDirective = inject(TableContextDirective, {
    optional: true,
  });
  private displayedColumnKeys: string[] = [];

  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly dropList = inject(CdkDropList, { optional: true });
  private readonly sanitizer = inject(DomSanitizer);

  @HostBinding('class.extendable') fullHeight = true;

  @Input() data: T[];
  @Input() dataService?: GqlTableService<GQL, F, T, QV, unknown>;
  @Input() hasPaginator = true;
  @Input() term: UntypedFormControl;
  @Input() enableCheckbox: boolean = true;
  @Input() allowMultiSelect: boolean = true;
  @Input() allowColumnReordering?: boolean = true;
  @Input() allowColumnResizing?: boolean = true;
  @Input({ transform: booleanAttribute }) set withPersistentColumns(
    value: boolean,
  ) {
    this.dataService?.setPersistentColumns(value);
  }
  @Input() columnsVisibilityControl?: boolean =
    inject(COLUMNS_VISIBILITY_CONTROL, { optional: true }) ?? true;
  @Input() linkedRows: boolean =
    inject(CLICKABLE_ROWS, { optional: true }) ??
    inject(CLICKABLE_LINKS, { optional: true }) ??
    true;
  @Input({ transform: booleanAttribute }) hasFooter: boolean = false;
  @Input({ transform: booleanAttribute }) hasFullWidth: boolean = false;
  @Input() dataSource: GqlTableDataSource<T>;
  @Input() displayedColumns: string[];
  @Input() sort: MatSort | undefined; // TODO redo with directives composition after upgrade to v15
  @Input() idKey: string = 'id';

  @ContentChildren(MatLegacyHeaderCellDef)
  headerCellDefs: QueryList<MatLegacyHeaderCellDef>;
  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  @ContentChildren(MatHeaderCell, { read: ElementRef })
  headerCells: QueryList<ElementRef>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true, read: MatTable }) table: MatTable<T>;
  @ViewChild(MatTable, { static: true, read: ElementRef }) tableEl: ElementRef;
  @ViewChild(MatDrawer, { static: true }) drawer: MatDrawer;
  @ViewChild('tableWrapper', { read: ElementRef })
  tableWrapperEl: ElementRef<HTMLDivElement>;
  @ViewChild(PageRightDrawerContentDirective, { static: true })
  rightDrawerContent: PageRightDrawerContentDirective;

  @Output() getSelectedRows = new EventEmitter();

  ngAfterContentInit() {
    this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));

    this.populateSelectableColumnsMap();

    this.headerRowDefs.forEach(headerRowDef =>
      this.table.addHeaderRowDef(headerRowDef),
    );

    this.columnDefs.forEach(columnDef => {
      this.displayedColumnKeys.push(columnDef?.name as string);
      this.table.addColumnDef(columnDef);
    });
  }

  ngOnInit() {
    if (!this.data) {
      this.sort?.sort(<MatSortable>{
        ...this.dataService?.getSortChange(),
        disableClear: true,
      });
      this.dataService
        ?.connect()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.selection.deselect(...this.selection.selected));
    }

    if (!this.dataSource) {
      this.dataSource = this.dataService as unknown as GqlTableDataSource<T>;
    }
  }

  ngAfterViewInit() {
    this.selection = new SelectionModel<T>(this.allowMultiSelect);
    const isReturnBackNavigation =
      ReturnUrlService.IS_RETURN_BACK_URL in
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.currentNavigationState as any);

    queueMicrotask(() => {
      if (!this.data && this.hasPaginator) {
        this.dataService?.setPaginator(this.paginator, isReturnBackNavigation);
      }
      this.dataService?.restoreTableColumns();

      if (!this.data) {
        if (this.sort) {
          this.dataService?.setSort(this.sort);
        }

        if (isReturnBackNavigation || this.dataService?.isPopstateNavigation) {
          this.dataService
            ?.connect()
            .pipe(skip(1), takeUntil(this.destroy$))
            .subscribe(() => {
              this.cdRef.detectChanges();
              this.tableWrapperEl.nativeElement.scrollTo({
                top: this.dataService?.getScrollPosition(),
              });
            });
        }

        this.dataService?.loadGqlData();
      }
    });
  }

  private populateSelectableColumnsMap() {
    const columnDefs: ColumnDef[] = [];
    const columnDefsArray = this.columnDefs.toArray();
    let withStickyColumns = false;

    this.headerCellDefs.forEach((headerCellDef, index) => {
      const ref = headerCellDef.template.createEmbeddedView(null);
      ref.detectChanges();
      const columnDef: MatLegacyColumnDef | undefined = columnDefsArray[index];
      withStickyColumns = withStickyColumns || columnDef?.sticky;

      columnDefs.push({
        key: columnDef?.name,
        nameHtml: ref.rootNodes[0]?.innerHTML,
      });
      ref.destroy();
    });
    this.dataService?.setTemplateColumnsToMap(columnDefs);

    this.allowColumnReordering =
      this.allowColumnReordering && !withStickyColumns;
    this.hasFullWidth = withStickyColumns;

    if (this.dropList) {
      this.dropList.disabled = !this.allowColumnReordering;
    }
  }

  dropDisplayedColumn(event: CdkDragDrop<string[]>) {
    this.dataService?.dropDisplayedColumn(event);
  }

  protected isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
    this.getSelectedRows.emit(this.selection.selected);
  }
  rowSelect(row: T) {
    this.selection.toggle(row);
    this.getSelectedRows.emit(this.selection.selected);
  }

  onScroll() {
    this.dataService?.setScrollPosition(
      this.tableWrapperEl.nativeElement.scrollTop,
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.dataService?.disconnect();
  }

  exportAsXLSX() {
    const fileName = 'demo.xlsx';
    let wb: XLSX.WorkBook;
    let ws: XLSX.WorkSheet;
    if (this.selection.selected.length > 0) {
      ws = XLSX.utils.json_to_sheet(this.selection.selected);
      wb = XLSX.utils.book_new();
    } else {
      ws = XLSX.utils.table_to_sheet(this.tableEl.nativeElement);
      wb = XLSX.utils.book_new();
    }
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName);
  }

  protected trackById = (_index: number, item: T) => {
    return (item as { [K: string]: string | number })[this.idKey];
  };

  protected getDisplayedColumns = () =>
    this.displayedColumns ?? this.dataService?.displayedColumns;
}
